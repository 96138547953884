.pageTitle {
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 25px;
  margin-top: 25px;
  color: #221d17;
}
.progressDiv {
  height: 120px;
  position: relative;
}
.fixedSteps {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: row;
}
.step {
  height: 40px;
  width: 40px;
  text-align: center;
  background: #e9e7e6;
  /* Gray-400 */
  border: 1px solid #d2cbc7;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #000000;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
}

.step.active {
  background-color: #c26a00;
  color: #ffffff;
}

.step.completed {
  background-color: #000000;
  color: #ffffff;
}

.stepText {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  align-items: center;
  color: #221d17;
  margin-top: 15px;
}

.stepText.active {
  font-weight: bold;
  font-size: 13px;
  color: blue;
  /* margin-left: -24px; */
}

.stepText.left {
  margin-left: -35px;
}
.stepText.right {
  margin-right: -25px;
}

.content-box {
  background: #ffffff;
  border: 1px solid #e9e7e6;
  box-sizing: border-box;
  box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 15px 50px;
  margin-bottom: 20px;
}

.headingText {
  margin-top: 15px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.bulletLogoBox {
  background-color: #e9e7e6;
  height: 48px;
  width: 48px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bulletBox {
  display: flex;
}

.bulletText {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22.5px;
  color: #221d17;
  margin-bottom: 0px;
}

.acceptButton,
.acceptButton:hover,
.acceptButton:active,
.acceptButton:focus {
  all: unset;
  cursor: pointer;
  width: 156px;
  height: 42px;
  left: 642px;
  top: 836px;
  box-shadow: none !important;
  /* Core-Primary */

  background: #c26a00;
  border-radius: 4px;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.acceptButton.ideal {
  background: #ffffff;
  color: #605b56;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #d2cbc7;
  margin-right: 15px;
}

.actionButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}
.optionBoxButton,
.optionBoxButton:hover,
.optionBoxButton:active,
.optionBoxButton:focus {
  all: unset;
  width: 100%;
  cursor: pointer;
  box-shadow: none !important;
}
.optionBox {
  background: #ffffff;
  /* Gray - 300 */
  border: 1px solid #e9e7e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
}

.optionBoxSelected {
  background: #fcf6ef;
  /* Gray - 300 */
  border: 1px solid #c26a00;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
}

.labelDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.labelDivText {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0;
  line-height: 25px;
  color: #221d17;
}

.checkDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialMediaButtons,
.socialMediaButtons:hover,
.socialMediaButtons:active,
.socialMediaButtons:focus {
  all: unset;
  cursor: pointer;
  box-shadow: none !important;
  margin-right: 15px;
  margin-left: 15px;
}

.dividerDiv {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dividerLine {
  width: 100%;
  border: 1px solid #e9e7e6;
}

.dividerBox {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 10px;
}

.dividerBoxText {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: #221d17;
  margin-bottom: 0;
}

.formLabel {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #221d17;
}

.registrationFormInputBox {
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  font-size: 16px;
  color: #000000;
  font-family: 'Quicksand', sans-serif;
  border-radius: 4px;
  padding: 5px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 36px;
}
.registrationFormInputBox {
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  font-size: 16px;
  color: #000000;
  font-family: 'Quicksand', sans-serif;
  border-radius: 4px;
  padding: 5px;
  /* display: block; */
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 36px;
}
.registrationFormPhoneBox {
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  font-size: 16px;
  color: #000000;
  font-family: 'Quicksand', sans-serif;
  border-radius: 4px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 36px;
}
.input-group-text {
  background-color: #f5f5f3;
  margin: 10px 0;
  height: 36px;
}
.conditionText {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #221d17;
  margin-bottom: 0px;
}

.links {
  all: unset;
  color: #c26a00;
  cursor: pointer;
}

.completedBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.completeTextLine1 {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #605b56;
  margin-top: 20px;
}

.completeTextLine2 {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #605b56;
}

.allViewers {
  height: 20px;
  width: 20px;
  border: 1px solid #ffffff;
  margin-left: -8px;
}

/*          volunteer   basic information and address     CSS start           */

.volunterr_address_head {
  padding-top: 15px;
  padding-left: 15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #221d17;
}
.volunteer_paragraph {
  padding-left: 15px;
  padding-top: 20px;
  font-family: 'Quicksand', sans-serif;
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
.main_container_addressing {
  margin-top: 10px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 3px 3px 3px rgb(207, 207, 207);
}
.basic_information_in_address {
  padding-top: 10px;
  padding-bottom: 15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #221d17;
}
.container_for_width_padding {
  width: 80%;
}
.name_and_last_name input {
  margin-bottom: 25px;
  padding-left: 15px;
  height: 36px;
  width: 100%;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.number_and_email_number input {
  padding-left: 15px;
  height: 36px;
  width: 97%;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.address_of_address {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #221d17;
}
.address_line_one input,
.address_line_two input {
  margin-bottom: 20px;
  padding-left: 15px;
  height: 36px;
  width: 100%;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.address_of_city {
  padding-left: 15px;
  height: 36px;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.address_of_select_option_state {
  padding-left: 15px;
  height: 36px;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  width: 20%;
  margin-left: 21px;
}
.address_of_zip {
  padding-left: 15px;
  height: 36px;
  width: 90%;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.address_of_select_option_hour {
  padding-left: 15px;
  height: 36px;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.avilable_hour_in_address,
.desired_focus_area {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #221d17;
}
.address_of_other_select_option {
  margin-bottom: 15px;
  padding-left: 15px;
  height: 36px;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  width: 100%;
}
.address_of_comment_address textarea {
  resize: none;
  padding-top: 15px;
  margin-bottom: 10px;
  padding-left: 15px;
  height: 72px;
  width: 100%;
  background: #f5f5f3;
  border: 1px solid #d2cbc7;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
}
.volunteermatch {
  color: #c26a00;
  text-decoration: none;
}
.term_and_condition_in_address {
  padding-left: 10px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #221d17;
}
.both_button_can_and_submit {
  margin-top: 20px;
  float: right;
}
.cancle_btn_in_address {
  background: #ffffff;
  border: 1px solid #c26a00;
  box-sizing: border-box;
  border-radius: 4px;
  color: #c26a00;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  height: 42px;
  width: 101px;
  border-radius: 4px;
  padding: 12px, 24px, 12px, 24px;
}
.submit_btn_in_address {
  margin-left: 30px;
  height: 42px;
  width: 100px;
  border-radius: 4px;
  padding: 12px, 24px, 12px, 24px;
  background: #c26a00;
  border-radius: 4px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  border: none;
  color: white;
}

/*          volunteer   basic information and address     CSS End           */

/*         Notification    Css start          */

.all_count_notification {
  border: none;
  background-color: white;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  display: inline-block;
  color: black;
}
.all_count_notification span,
.unread_notification span {
  border: 1px solid gray;
  border-radius: 10px;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 5px;
  background: #fcf6ef;
  color: black;
  font-weight: 700;
}
.mark_all_as_read_notification {
  display: inline-block;
  float: right;
}
.mark_all_as_read_notification button {
  border: none;
  color: #c26a00;
  background-color: white;
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}
.main_container_spacing {
  padding-bottom: 15px;
  padding-top: 15px;
}
.unread_notification {
  border: none;
  background-color: white;
  margin-left: 20px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  display: inline-block;
  color: #605b56;
}
.horizontal_line_all_notification {
  border: 1px solid #e9e7e6;
}
.new_for_notification {
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  color: #221d17;
  padding-bottom: 15px;
}
.new_name_comment_notification {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #221d17;
}
.invitation_for_notification {
  padding-top: 5px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #605b56;
}
.btn_of_acpt_dcln_notification {
  margin-bottom: 10px;
}
.btn_of_acpt_dcln_notification button {
  padding-left: 0px;
  margin-left: 0px;
  margin-right: 20px;
  padding-top: 10px;
  background-color: white;
  border: none;
  color: #c26a00;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}
.image_under_container {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 5px;
  /* margin-left: px; */
  /* width: 130%; */
  height: 50%;
  background: #f1f6ff;
}
.image_under_container2 {
  padding-top: 5px;
  padding-left: 11px;
  margin-left: px;
  width: 130%;
  height: 47%;
  background: #f5f5f3;
}
.date_of_notification {
  float: right;
  margin-right: -15px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  color: #a09f9e;
}
.secondry_circle {
  margin-right: -15px;
  float: right;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  right: 16px;
  top: 50px;
  background: #06d0c9;
}
.save_our_plnt {
  color: #c26a00;
  font-weight: 700;
}
.green_earth {
  color: #c26a00;
  font-weight: 700;
}
.older_name_comment_notification {
  margin-top: px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #a09f9e;
}

.rangeLabel {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #c26a00;
  margin-bottom: 0px;
  margin-left: 15px;
}


.successImg{
  height: 150px;
  width: 150px;   
}

.successMsgText {
  margin-top: 15px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: #000000;
}

.successMessageText{
  margin-top: 15px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
@media (max-width: 767px) {
  .fixedSteps div {
    width: 40px;
  }

  .address_of_zip {
    margin-left: 0!important;
    width: 100%;
  }

  .address_of_select_option_hour {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 512px) {

  .max-width-96 {
    max-width: 96% !important;
  }

}

@media screen and (min-width: 513px) {

  /* .max-width-96 {
    max-width: 96% !important;
  } */
  .max-width-60 {
    max-width: 60% !important;
  }
  
}