.fixHeaderAdmin{
    height: 70px;
    background-color: #000000;
}
label.sc-bdvvtL.iciELI.testingDrop {
    height: 40px;
    border: 1px #c8c1c1 solid;
    min-width: 570px;
}
.mid_pic_admin{
    height: 70px;
}

.mid_pic_admin{
    margin-left: 20px;
}

.logoutLink, .logoutLink:hover{
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;
}

.contentArea{
    display: flex;
    height: calc(100vh - 70px);
}

.fixSideBar{
    width: 220px;
    background-color: #ffffff;
}

.fixContent{
    width: calc(100vw - 220px);
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.adminMenu{
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.adminMenu{
    margin: 0px;
}

.menuCol{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 45px;
}

.sideBarLink{
    color: #000000;
    font-size: 15px;
    margin-bottom: 0px;
    /* font-weight: bold; */
}
.menuLink{
    text-decoration: none;
}

.contentBoxAdmin{
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 15px;
    margin: 15px;
}

.pageHeading{
    margin-bottom: 0;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #221D17;
    margin-bottom: 0;
}

.dashboardBox{
    background: #FFFFFF;
    border: 1px solid #E9E7E6;
    box-sizing: border-box;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 15px;
    margin-top:20px;
    margin-bottom: 20px;
    /* display: flex; */
    text-align: center;
}

.dashboardValue{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: #221D17;
    margin-bottom: 0;
}

.dashboardLabel{
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    /* font-weight: bold; */
    font-size: 16px;
    color: #221D17;
    margin-bottom: 0;
}