.fixHeader {
  /* position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%; */
  background: #000000;
  /* height: 96px; */
}
.OffCanvas {
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  text-align: center;
}
.nav-link {
  color: #000;
  font-weight: 600;
}
.nav-link:hover,
.nav-link:active,
.nav-link:focus,
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active,
.active1 {
  background: aquamarine;
  color: black;
  border: none;
  /* border-radius: 12px; */
}
.dropdown-menu,
.navbar-toggler {
  padding: 0;
}
.dropdown-item {
  color: #000;
  font-weight: 600;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}
.activeFooterLink,
.activeFooterLink:hover {
  color: #e68f1c;
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  display: block;
  line-height: 26px;
}
.fixFooter {
  background: #000000;
  padding: 10px 0;
  padding-bottom: 30px;
}

.leftMenuSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
}

.centerMenuSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightMenuSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.p-relative {
  position: relative;
}

.logo-icon {
  margin-right: 10px;
  margin-left: 10px;
}

.donateLink,
.donateLink:hover {
  all: unset;
  cursor: pointer;
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  margin-right: 25px;
  font-weight: 700;
  font-size: 20px;
}

.footerCategory {
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
}

.footerLink,
.footerLink:hover {
  all: unset;
  cursor: pointer;
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  display: block;
  line-height: 26px;
}

.footerInputBox {
  border: 1px solid #d2cbc7;
  background-color: #000000;
  font-size: 16px;
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  border-radius: 4px;
  padding: 5px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 36px;
}

.footerSubmitButton {
  all: unset;
  height: 48px;
  background-color: #ffffff;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  color: #605b56;
}

.newsletterText {
  color: #ffffff;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.dashboard-menubar {
  display: flex;
  align-items: center;
  position: relative;
}

.stepText,
.stepText.active {
  white-space: nowrap;
}

.menu-icon {
  margin-left: 25px;
}

.address_of_city {
  width: 100% !important;
}

.agree-btn {
  padding-left: 7px !important;
}

.tabs.active,
.tabs {
  white-space: nowrap;
}

/* .calender-btn-box {
  margin-bottom: 16px;
  display: none;
} */
.calenderButton {
  box-shadow: none !important;
  font-style: normal;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  height: 42px;
  width: 50%;
  color: #c26a00;
  border: 1px solid #c26a00;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
}
.calenderButtonActive {
  background-color: #c26a00 !important;
  color: #fff !important;
}
.calenderView {
  position: relative;
}
.calenderAddEventPlusBtn {
  position: absolute;
  right: -15px;
  top: -5px;
  box-shadow: 0px 1px 12px -2px #808080b3;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #fff;
  font-size: 2.5rem;
  background-color: #c26a00;
}

/* ==========Map Menu========= */
li[role='presentation'] .nav-link {
  white-space: nowrap;
  border: 1px solid #c26a1f;
  color: black !important;
}
li[role='presentation'] .nav-link.active {
  color: #fff !important;
}

/* Responsive here */

@media (min-width: 992px) and (max-width: 1170px) {
  .donateLink {
    margin-right: 12px;
  }

  .manageCausesBtn {
    margin-bottom: 15px!important;
  }

  .logo-icon {
    margin: 0 5px;
  }
}

/* tablet ******************************************************* */
@media (max-width: 992px) {
  .header-social {
    display: none;
  }

  .team_name_in,
  .first_name_in,
  .last_name_in,
  .about_me_in,
  .email_id_in,
  .site_name_in {
    width: unset !important;
  }
  .last_name_in {
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .tabs {
    white-space: nowrap !important;
  }

  .manageCausesBtn {
    /* display: none !important; */
    margin-top: 15px!important;
  }

  .p-relative {
    position: unset !important;
  }

  .dashboardColumn {
    align-items: center !important;
  }

  .border-l0 {
    border-left: 0px !important;
  }

  .imageSection {
    justify-content: center;
    margin-bottom: 20px;
  }

  .centerMenuSection {
    justify-content: flex-start;
  }

  .dashboard-menubar {
    overflow-x: scroll;
  }

  .number_and_email_number input:first-child {
    margin-bottom: 25px !important;
  }

  .address_of_city {
    width: 90% !important;
    margin-bottom: 20px;
  }

  .address_of_select_option_state {
    margin-left: unset !important;
    margin-right: 21px;
    width: 100% !important;
  }

  .address_of_zip {
    width: 90%;
    margin-left: 0% !important;
  }

  .address_of_select_option_hour {
    width: 100%;
  }
  /* scroll design */
  ::-webkit-scrollbar {
    height: 1px;
    padding-top: 10px;
  }

  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  .team_member_and_number p {
    margin-left: 12px !important;
  }
  .small_contain_4 {
    padding-left: 0 !important;
  }

  /* //scrollbar */
}

/* //tablet ********************************************************/

@media (max-width: 768px) {
  .second_row {
    position: relative !important;
  }
  .team_name_in,
  .first_name_in,
  .last_name_in,
  .about_me_in,
  .email_id_in,
  .site_name_in {
    width: unset !important;
  }
  .last_name_in {
    margin-left: 0;
    margin-top: 10px !important;
  }
  .leftMenuSection {
    display: none;
  }
  .small_contain_4 {
    padding-left: 5% !important;
  }
  .team_member_and_number p {
    margin-top: -20px;
    margin-left: unset !important;
  }

  .centerMenuSection {
    justify-content: flex-start;
  }

  .dashboard-menubar {
    padding-bottom: 10px;
  }

  .volunteer {
    display: none;
  }

  .stepText.left {
    margin-left: 0 !important;
  }

  .stepText.right {
    margin-right: 0 !important;
  }

  .logout {
    display: none;
  }

  .mt-sm-35 {
    margin-top: 35px !important;
  }

  /* footer */
  .footer-logo-bar {
    display: flex;
    justify-content: center;
    margin: 25px auto;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .icon-bar {
    justify-content: center;
    margin-bottom: 25px;
  }

  .header-social {
    display: none;
  }

  .content-box {
    padding: 15px !important;
  }

  .edit-profile-row {
    position: absolute;
    top: 0px !important;
    right: 10px;
  }
}
/* //768px */

/* Other Media query */
@media (max-width: 575px) {
  .team_name_in,
  .first_name_in,
  .last_name_in,
  .about_me_in,
  .email_id_in,
  .site_name_in {
    width: unset !important;
  }

  .res-margin {
    margin: 0 10px !important;
  }
  /*.event-info {*/
  /*  display: none;*/
  /*}*/
  .calender {
    display: block;
  }
  .listCard {
    margin-bottom: 15px;
    padding: 0px;
    background-color: #fff;
    /* border-bottom: 1px solid rgb(233, 231, 230); */
    margin: 30px 10px;
    border: 1px solid #ebebeb;
  }
  /* .calender-btn-box {
    display: block;
  } */
  .monthTitle {
    text-align: center;
    padding: 0 !important;
  }
  .todayDate {
    padding: 0 !important;
  }
  .calendarLabel {
    padding: 0 4px !important;
  }
  .calendarCell {
    height: 44px !important;
    width: 45.5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .calendarDate {
    margin-bottom: 0;
  }
  .eventDot {
    position: absolute;
    left: 45%;
    bottom: 2px;
  }
  .calendarEventName {
    display: none;
  }
  .calendarCell:hover .calendarEventName {
    display: block;
  }
  .month-blank-col {
    display: none;
  }
  /* .sub-menu-of-myteam{
        overflow-x: scroll;
    } */
  .eventsActionButton {
    width: 90% !important;
    text-align: center !important;
  }
  .eventsActionButton {
    margin-bottom: 16px !important;
  }
  .contentPart {
    margin: 20px 0px !important;
  }
  .customFilterBox {
    width: 100%;
    margin-right: 12px !important;
  }
  .container_for_width_padding {
    width: unset !important;
  }

  .address_of_city {
    width: 90% !important;
    margin-bottom: 20px;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  /* .covermain_pic_div img {
    height: 100px;
  } */
  .user_dp_div,
  .user_dp_image {
    height: 90px !important;
    width: 90px !important;
  }
  .user_dp_div {
    margin-top: -45px !important;
  }
  .spaceBetweenDivs {
    height: 50px !important;
  }
  .edit-profile-row {
    position: absolute;
    top: 0px !important;
    right: 10px;
  }
  .padding-left-only,
  .padding-right-only {
    padding: 0 15px !important;
  }
}
@media (max-width: 472px) {
  .sub-menu-of-myteam {
    overflow-x: scroll !important;
  }
}
@media (max-width: 461px) {
  .stepText,
  .stepText.active {
    font-size: 9px !important;
  }

  .donateLink {
    margin-right: 10px;
  }

  .conditionText {
    padding: 0 10px;
  }

  .logo-icon {
    margin-right: 5px;
    margin-left: 5px;
  }

  .menu-icon {
    margin-left: 0;
  }

  .p-relative {
    position: relative !important;
  }
}

@media (max-width: 375px) {
  .stepText,
  .stepText.active {
    font-size: 8px !important;
  }
}

/* 
.sidebar-css {
  background: var(--ifm-color-emphasis-600);
    color: var(--ifm-color-content-inverse);
} */



.sidebar-css {
  background: #8c949e !important;
  color: white !important;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: .08rem;
  padding: 0.75rem;
  text-transform: uppercase;
  line-height: 28px;
}